import React from "react";
import tw, {styled} from "twin.macro";

import { Link } from "../../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general.js";


const Container = styled.div`
  ${tw`w-full bg-center bg-cover px-12 mt-8 flex justify-center md:justify-end items-center `}
  ${props=>props.custom_css}
  background-image: ${(props) => `url(${props.background_image})`};
  background-size: cover;
  height: 300px;
@media only screen and (min-width: 590px) {
	background-attachment: fixed;
	height: 500px;
}
`;

const TwoColumn = styled.div`
${tw`flex justify-center items-center flex-col`}
`

const Heading = styled.h1`
  ${tw`text-xl leading-none text-center text-white lg:text-left sm:text-2xl lg:text-3xl xl:text-4xl`} 
  font-weight:700;
   
  span {
    ${tw`inline-block mt-4`}
  }
`; 


const Paragraph = tw.p`my-1 text-center md:text-left text-sm xl:text-base text-primary-900 md:my-2 `;
 
const PrimaryAction = styled(Link)`
${tw`px-8 py-3 text-sm font-medium transition duration-300 shadow sm:text-base mt-1 hover:no-underline hocus:text-gray-100 focus:shadow-outline`}
background-color:${
  props=>props.backgroundColor
};
color:${
  props=>props.textColor
};
`
export default ({data,page}) => {
   
    let finalData= data?.schema_json;  
  
      let content=  {
          heading:'What will you <br />benifit from?',
          description:``,
          background_image:'//cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_Couple_in_Kitchen_1728x.jpg?v=1600302123', 

          button_text: `ORDER NOW + FREE SHIPPING`,
          button_url: '/products/buy-prolon', 
          background_color: '#ff6353',
          text_color:'#fff', 
        ...finalData
      }

console.log('what will Scroll')

return (
    <Container custom_css={content?.custom_css}  background_image={content?.background_image}>
        <TwoColumn custom_css={content?.custom_css}>
            <Heading>
              <div dangerouslySetInnerHTML={{__html:content?.heading}}></div>
            </Heading>
    
            <Paragraph  dangerouslySetInnerHTML={{__html:content?.description}} ></Paragraph>

                <PrimaryAction
                href={content?.open_new_tab ? content?.button_url : null}
                to={content?.open_new_tab ? null : content?.button_url}
                target="_blank"
                backgroundColor={content?.background_color}
                textColor={content?.text_color}
                >
                  {content?.button_text}
                </PrimaryAction>

        </TwoColumn>
    </Container>
  );
};
